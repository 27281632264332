<template>
  <div>
    <div class="text-center mb-2">
      <el-radio-group v-model="field.is_global_variable" >
        <el-radio-button :label="false">Custom Field</el-radio-button>
        <el-radio-button :label="true">Global Variable</el-radio-button>
      </el-radio-group>
    </div>

      


    <el-form v-if="!field.is_global_variable">
      <title-and-description :field="field" />
      <el-row :gutter="50">
        <el-col :span="24">
          <div class="form-group">
            <el-form-item >
            <label class="field-title-label">
              Content<span class="red-asterisk">*</span>
            </label>
            <el-input
              type="textarea"
              v-model="field.content"
               placeholder="Enter content" 
                rows="10"
                cols="200"
            ></el-input>
            <p
              v-if="field.label && !field.label.length"
              class="error"
            > *Label is required*</p>
            <FormError error-key="label" /> 
          </el-form-item> 
          </div>
        </el-col></el-row>
    </el-form>



    
    <el-form label-position="right" v-if="field.is_global_variable">
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <div class="form-group">
          
            <el-form-item label="Global Variable">
              <el-select v-model="field.global_variable_id" @change="setGlobalVariable">
                <el-option
                  v-for="(globalVariable,index) of allGlobalVariables"
                  :key="index"
                  :label="globalVariable.label"
                  filterable
                  :value="globalVariable._id"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-col>
      </el-row>


    </el-form>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
export default {

  name:"templates-formComponents-Html",
  props: ["field"],
  computed: {
    ...mapGetters("globalVariables", ["getAllGlobalVariables"]),
    allGlobalVariables() {
      console.log("getAllGlobalVariables",this.getAllGlobalVariables);
      return this.getAllGlobalVariables
        ? this.getAllGlobalVariables.data || []
        : [];
    }
  },
   components: {
    TitleAndDescription: () => import("./TitleAndDescription"),
    // FieldAttributes: () => import("./FieldAttributes"),
  },
  data() {
    return {};
  },
  mounted() {
     this.field['filled_by'] = 'SENDER';
     this.fetchGlobalVaribales();
  },
  methods: {
    async fetchGlobalVaribales() {
     
      let params = {
        get_all: true,
        input_type: this.field.input_type
      };

      await this.$store.dispatch(
        "globalVariables/fetchGlobalVariables",
        params
      );
    },
    setGlobalVariable() {
      let globalVariable = this.allGlobalVariables.find(
        x => x._id == this.field.global_variable_id
      );
      this.field.label = globalVariable.label;
      this.field.description = globalVariable.description;
      this.field.content = globalVariable.value;
    }
  }
};
</script>

<style lang="scss">

</style>